<template>
  <div class="hero-with-image" :class="{ 'has-background': backgroundImage }">
    <div v-if="backgroundImage" class="background">
      <NuxtImgWrapper :src="backgroundImage.source" alt="" v-parallax />
    </div>
    <div class="left">
      <div class="top-content">
        <Headline
          :aside="headline.aside"
          :text="headline.text"
          :more="headline.more"
          :inverted="!backgroundImage"
        >
          {{ headline.title }}
        </Headline>
        <div class="image-wrapper mobile">
          <NuxtImgWrapper
            preload
            v-if="image"
            :src="image.source"
            :alt="image.alt"
            :width="image.width"
            :height="image.height"
            disable-lazy-loading
          />
          <div v-else-if="vimeoId" class="video-wrapper">
            <iframe
              ref="vimeoVideo"
              :src="
                'https://player.vimeo.com/video/' +
                vimeoId +
                '?dnt=true&pip=false&title=false&portrait=false&byline=false&background=' +
                (state.fullscreen ? 'false' : 'true')
              "
              allow="fullscreen"
              allowfullscreen
              :width="state.videoSizes.width || 1280"
              :height="state.videoSizes.height || 720"
              frameborder="0"
              loading="lazy"
            ></iframe>
            <IconsPlay @click="toggleVideoFullscreenPlay()" />
          </div>
        </div>
      </div>
      <div v-if="anchorList" class="anchor-list">
        <nav>
          <ul>
            <li
              v-for="el of anchorList"
              :key="'hero-list-' + el.name"
              :class="{ active: activeAnchor && activeAnchor === el.name }"
            >
              <a :href="'#' + el.name" @click="$emit('anchor', el.name)">
                <span class="title">{{ el.title }}</span>
                <Icon icon="arrow-down" />
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <slot />
    </div>
    <div class="right">
      <div class="image-wrapper">
        <NuxtImgWrapper v-if="image" :src="image.source" :alt="image.alt" />
        <div v-else-if="vimeoId" class="video-wrapper">
          <iframe
            ref="vimeoVideo"
            :src="
              'https://player.vimeo.com/video/' +
              vimeoId +
              '?dnt=true&pip=false&title=false&portrait=false&byline=false&background=' +
              (state.fullscreen ? 'false' : 'true')
            "
            allow="fullscreen"
            allowfullscreen
            loading="lazy"
            :width="state.videoSizes.width || 1280"
            :height="state.videoSizes.height || 720"
            frameborder="0"
          ></iframe>
          <IconsPlay @click="toggleVideoFullscreenPlay()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Vimeo from '@vimeo/player'
import type { ImageItem, VideoItem } from '~/types/base'
type Headline = {
  aside: string
  title: string
  text?: string
  more?: boolean
}
type Anchor = {
  title: string
  name: string
}

const props = defineProps<{
  headline: Headline
  image?: ImageItem
  vimeoId?: VideoItem
  backgroundImage?: ImageItem
  anchorList?: Anchor[]
  activeAnchor?: Anchor
}>()

const state = reactive({
  fullscreen: false,
  videoSizes: Object as () => { width: string; height: string } | null,
})

const vimeoVideo = ref<any>(null)
let player: any = null

onMounted(async () => {
  if (vimeoVideo.value) {
    player = new Vimeo(vimeoVideo.value)
    state.videoSizes = await getVideoSizes()
  }
})

watch(
  () => player,
  () => {
    console.log('player: ', player.getVideoHeight(), player.getVideoWidth())
    if (player) {
      state.videoSizes = getVideoSizes()
    }
  }
)

async function getVideoSizes() {
  const height = await player.getVideoHeight()
  const width = await player.getVideoWidth()
  return { height, width }
}

function toggleVideoFullscreenPlay() {
  console.log('toggle vimeo play')
}

const videoWidth = computed(() => state.videoSizes?.width || 1280)
const videoHeight = computed(() => state.videoSizes?.height || 720)
</script>

<style lang="scss" scoped>
.hero-with-image {
  position: relative;
  padding-left: $offset;
  color: $color-inverted;
  &.has-background {
    padding-bottom: $column-md;
    .anchor-list {
      color: $color-inverted;
    }
  }
  &:not(.has-background) {
    background: white;
  }
  @media (min-width: $bp-md) and (orientation: landscape) {
    display: flex;
    justify-content: space-between;
    padding-left: $offset-md;
  }
}
.left {
  @media (min-width: $bp-md) and (orientation: landscape) {
    width: $col-span * 6;
  }
}
.right {
  display: none;
  .image-wrapper {
    position: sticky;
    display: flex;
    height: 100vh;
    top: 0;
    img,
    .video-wrapper {
      margin-top: auto;
    }
    .video-wrapper,
    iframe {
      aspect-ratio: v-bind(videoWidth) / v-bind(videoHeight);
      width: 100%;
      height: auto;
    }
  }
  @media (min-width: $bp-md) and (orientation: landscape) {
    position: relative;
    display: block;
  }
}
.top-content {
  padding-top: $header-height * 3;
  padding-bottom: $col-span;
  @media (min-width: $bp-md) and (orientation: landscape) {
    display: flex;
    flex-wrap: wrap;
    padding-top: $header-height-md * 3;
  }
  @media (min-width: $bp-tablet) {
    min-height: 100vh;
  }
}
.background {
  overflow: hidden;
  position: absolute;
  inset: 0;
  &:after {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(179deg, rgba(3, 7, 18, 0) 57%, #030712 98%);
  }
  :deep(.simpleParallax) {
    height: 100%;
  }
  img {
    object-fit: cover;
    height: 100%;
  }
}
.headline,
.image-wrapper,
.anchor-list {
  position: relative;
  z-index: 1;
}
.headline {
  margin-bottom: 2em;
  padding-right: $offset;
  @media (min-width: $bp-md) and (orientation: landscape) {
    width: $col-span-md * 4 + $column-md;
    margin-bottom: unset;
    padding-right: unset;
  }
}
.image-wrapper {
  line-height: 0;
  &.mobile {
    .video-wrapper,
    iframe {
      //aspect-ratio: 16/9;
      aspect-ratio: v-bind(videoWidth) / v-bind(videoHeight);
      width: 100%;
      height: auto;
    }
  }
  .video-wrapper {
    position: relative;
    .play {
      translate: -50% -50%;
      position: absolute;
      width: 10em;
      height: 10em;
      top: 50%;
      left: 50%;
      font-size: max(1.2rem, 1.25vw);
    }
  }
  @media (min-width: $bp-md) and (orientation: landscape) {
    width: ($col-span-md * 6) + $offset-md;
    margin-left: auto;
    &.mobile {
      display: none;
    }
  }
}
.anchor-list {
  font-size: max(1.4rem, 1.25vw); // 18
  color: $color-text;
  ul {
    min-height: 22ch; // fixes jittering
  }
  li {
    position: relative;
    margin-bottom: 0.65em;
    > a {
      display: flex;
      align-items: baseline;
    }
    &.active {
      span {
        font-size: $font-30;
        font-weight: $weight-semibold;
        color: $color-primary;
      }
    }
    span {
      transition: font-size, font-weight, color, margin;
      transition-duration: 0.33s;
    }
    svg {
      transition: translate, color;
      transition-duration: 0.33s;
      translate: 0 -0.1em;
      width: 0.5em;
      min-width: 0.5em;
      height: 0.5em;
      margin-left: 0.5em;
    }
    @media (hover: hover) {
      &:hover {
        span {
          color: $color-primary;
        }
        svg {
          translate: 0 0.05em;
        }
      }
    }
  }
  @media (min-width: $bp-md) and (orientation: landscape) {
    padding-left: $col-span-md;
    li {
      width: fit-content;
    }
  }
}
</style>
