<template>
  <div class="headline" :class="{ inverted }">
    <span v-if="aside" class="aside" v-html="aside"></span>
    <h1 v-html="headline"></h1>
    <p v-if="text" class="text" v-html="text"></p>
    <a v-if="more" href="#more" class="more animated-link">
      <p>
        <span>{{ $t('general.more') }}</span
        ><Icon icon="arrow-down" />
      </p>
    </a>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  aside?: string
  text?: string
  more?: boolean
  inverted?: boolean
}>()

const slots = useSlots()
const isColorSchemeInverted = colorSchemeIsInverted()

const headline = computed(() => slots.default()[0]?.children)

const textColor = computed(() =>
  isColorSchemeInverted.value ? '#030712' : '#7B808B'
)
</script>

<style lang="scss" scoped>
.headline {
  font-size: $font-28;
  &.inverted {
    h1,
    p,
    a {
      color: $color-black;
    }
  }
}
.aside {
  display: block;
  margin-bottom: 0.5em;
  font-size: max(1.4rem, 1.25vw); // 18
  color: $color-primary;
}
h1 {
  font-size: $font-50;
  color: $color-inverted;
}
.text {
  margin-top: 1.75em;
  font-size: $font-28;
  font-weight: $weight-light;
  line-height: 1.6;
  color: $color-darkgray;
}

.more {
  position: relative;
  display: block;
  margin-top: 3em;
  font-size: max(1.4rem, 1.25vw); // 18
  &:before {
    content: '';
    position: absolute;
    width: 140%;
    height: 160%;
    top: -30%;
    left: -10%;
  }
  p {
    display: flex;
    align-items: center;
  }
  svg {
    transition: translate 0.25s;
    position: absolute;
    width: 0.5em;
    height: 0.5em;
    right: -0.9em;
    color: $color-primary;
  }
  @media (hover: hover) {
    &:hover {
      svg {
        transition: translate 0.25s 0.15s;
        translate: 0 2px;
      }
    }
  }
}
</style>
