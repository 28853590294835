<template>
  <section class="attention-headline standard-padding">
    <span class="aside">{{ aside }}</span>
    <div class="content">
      <h1><slot /></h1>
      <p v-if="text" class="text" v-html="text"></p>
      <slot name="content" />
      <nuxt-link v-if="button" :to="button.route">
        <AppButton
          :red="button.red"
          :borderless="button.borderless"
          :icon="button.icon"
          >{{ button.title }} <Download v-if="button.download"
        /></AppButton>
      </nuxt-link>
    </div>
  </section>
</template>

<script lang="ts" setup>
import Download from '~/components/Icons/Download.vue'

const props = defineProps<{
  aside: string
  text?: string
  button?: {
    route: string
    title: string
    red?: boolean
    download?: boolean
    icon?: string
    borderless?: boolean
  }
}>()

const isColorSchemeInverted = colorSchemeIsInverted()

const textColor = computed(() =>
  isColorSchemeInverted.value ? '#030712' : '#7B808B'
)
</script>

<style lang="scss" scoped>
.attention-headline {
  @media (min-width: $bp-md) {
    display: flex;
  }
}
.aside {
  display: block;
  margin-bottom: 1em;
  font-size: max(1.4rem, 1.25vw); // 18
  color: $color-text;
  @media (min-width: $bp-md) {
    width: $col-span-md * 2;
    margin-right: $col-span-md;
  }
}
.content {
  > a {
    display: block;
    width: fit-content;
  }
  @media (min-width: $bp-md) {
    width: $col-span-md * 8 + $column-md;
  }
}
h1 {
  margin-bottom: 1.5em;
  font-size: $font-50;
  font-weight: $weight-normal;
  white-space: break-spaces;
  letter-spacing: -0.03em;
  color: $color-primary;
}
p {
  margin-top: 1.75em;
  font-size: $font-28;
  font-weight: $weight-light;
  line-height: 1.6;
  color: $color-darkgray;
}

:deep(.button) {
  .download {
    width: 0.9em;
    min-width: 0.9em;
    height: 0.9em;
    min-height: 0.9em;
    margin-left: 1.5em;
  }
  @media (hover: hover) {
    &:hover {
      .download {
        .arrow {
          translate: 0 1px;
        }
      }
    }
  }
}
</style>
